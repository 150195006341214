import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export default function DndSortable({ id, isSortable, disabled = false, children }) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: id, disabled: disabled });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    if (!isSortable) {
        return (
            <>
                {children}
            </>
        );
    }

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {children}
        </div>
    );
}